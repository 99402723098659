import _ from 'lodash'
var jsstats = require('js-stats')

// Unique Alerts Handle
export const isEqualVariances = (n1, n2, s1, s2) => {
    var alertText = 'Based on your inputs for s1 and s2 the assumption of equal variances is not valid'
    var { Fvalue, df1, df2 } = 0
    if (s1 >= s2) {
        df1 = n1 - 1
        df2 = n2 - 1
        Fvalue = Math.pow(s1, 2) / Math.pow(s2, 2)
    } else {
        df1 = n2 - 1
        df2 = n1 - 1
        Fvalue = Math.pow(s2, 2) / Math.pow(s1, 2)
    }
    var Fdistribution = new jsstats.FDistribution(df1, df2)
    var p = Fdistribution.cumulativeProbability(Fvalue)
    if (p >= 0.90) {
        // Alert
        alert(alertText)
        return false
    }
    return true
}

// Standard Alerts Handle
export const isInvalidNums = (...numbers) => {
    const invalid = _.some(numbers, number => number === 0)
    const invalid2 = _.some(numbers, number => (!number || isNaN(number)))
    if (invalid) {
        return false
    } else if (invalid2) {
        alert('Inputs must be valid numbers')
        return true
    }
}

export const isEmptyNums = (...numbers) => {
    const numStrings = _.map(numbers, number => _.toString(number))
    const invalid = _.some(numStrings, number => (!number.trim().length))
    if (invalid) {
        alert('Inputs must be valid numbers')
        return true
    }
}

export const isErrorCI = (...numbers) => {
    const nums = _.map(numbers, number => _.toNumber(number))
    const invalid = _.some(nums, number => (number >= 100 || number <= 1))
    if (invalid) {
        alert('Confidence Level must be less than 100 & greater than 1')
    }
    return invalid
}

export const isNotPercentage = (...numbers) => {
    const invalid = _.some(numbers, number => (number <= 0 || number >= 1))
    if (invalid) {
        alert('Probability must be between 0 and 1 ')
        return true
    }
}

export const isNegative = (...numbers) => {
    const invalid = _.some(numbers, number => (number <= 0))
    if (invalid) {
        alert('Standard deviation, must be a positive number')
        return true
    }
}

export const isNotWholeNumber = (...numbers) => {
    const invalid = _.some(numbers, number => (!Number.isInteger(number) || number <= 1))
    if (invalid) {
        alert('Sample size must be a whole number > 1')
        return true
    }
}

export const isNotPosWholeNumberSS = (...numbers) => {
    numbers = _.map(numbers, _.toNumber)
    const invalid = _.some(numbers, number => (!_.isInteger(number) || number < 1))
    if (invalid) {
        alert('Sample size must be a whole number > 1')
        return true
    }
}

export const isNotPosWholeNumberXN = (...numbers) => {
    numbers = _.map(numbers, _.toNumber)
    const invalid = _.some(numbers, number => (!_.isInteger(number) || number < 1))
    if (invalid) {
        alert('Inputs for x and n must be positive whole numbers')
        return true
    }
}

export const isNotPosWholeNumberDF = (...numbers) => {
    numbers = _.map(numbers, _.toNumber)
    const invalid = _.some(numbers, number => (!_.isInteger(number) || number < 1))
    if (invalid) {
        alert('df must be a positive whole number')
        return true
    }
}

export const isValidN = (num1) => {
    if (!Number.isInteger(num1) || num1 < 30) {
        alert('Sample size must be a positive whole number ≥ 30')
        return true
    }
}

export const isValidTrials = (num1) => {
    if (!Number.isInteger(num1) || num1 <= 0) {
        alert('Trials must be a positive whole number')
        return true
    }
}

export const isLessThanTwo = (num1) => {
    if (num1 < 2) {
        alert('Sample size must be ≥ 2')
        return true
    }
}

export const isXNegative = (num1) => {
    if (num1 <= 0) {
        alert('Successes must be positive')
        return true
    }
}

// Custom Alerts Handle
export const isInvalidNumsCustom = (...numbers) => {
    const invalid = _.some(numbers, number => number === 0)
    const invalid2 = _.some(numbers, number => (!number || isNaN(number)))
    if (invalid) {
        return false
    } else if (invalid2) {
        return true
    }
}

export const isEmptyNumsCustom = (...numbers) => {
    const numStrings = _.map(numbers, number => _.toString(number))
    const invalid = _.some(numStrings, number => (!number.trim().length))
    if (invalid) {
        return true
    }
}

export const isNegativeCustom = (...numbers) => {
    const invalid = _.some(numbers, number => (number < 0))
    if (invalid) {
        return true
    }
}

export const isNegativeCustomAB = (...numbers) => {
    const invalid = _.some(numbers, number => (number < 0))
    if (invalid) {
        return true
    }
}

export const isNotPercentageCustom = (...numbers) => {
    const invalid = _.some(numbers, number => (!_.inRange(number, 0, 1)))
    if (invalid) {
        return true
    }
}

export const isNotWholeNumberCustom = (...numbers) => {
    const invalid = _.some(numbers, number => !Number.isInteger(number))
    if (invalid) {
        return true
    }
}

export const isLessThanTwoCustom = (...numbers) => {
    const invalid = _.some(numbers, number => (!Number.isInteger(number) || number <= 1))
    if (invalid) {
        return true
    }
}

// Input Formatting
export const clearData = (data, pickData) => {
    var dataTemp = _.pick(data, pickData)
    var output = _.mapValues(dataTemp, v => {
        if (_.isArray(v) || _.isObjectLike(v)) {
            return []
        } else {
            return ''
        }
    })
    return output
}

export const dataToNums = (data, pickData) => {
    var dataTemp = _.pick(data, pickData)
    var output = _.mapValues(dataTemp, v => {
        return _.toNumber(v)
    })
    return output
}
