<template>
    <div
        v-if="group"
        class="m-auto d-flex justify-content-evenly"
    >
        <h3
            v-if="!label.text && label.mathChar"
            class="mr-3 mt-2 w-25"
        >
            <span class="m-char">{{ label.mathChar }}</span>
        </h3>
        <h3
            v-if="label && !label.mathChar"
            class="pl-2 mr-2 mt-2 w-25"
        >
            {{ label }}
        </h3>
        <input
            v-b-tooltip.hover.bottom="tip"
            :placeholder="placeHolder"
            :class="styleClass"
            :value="value"
            @input="updateInput"
            @keyup.enter="keyupEnter"
        >
    </div>
    <div
        v-else
        class="m-auto d-flex justify-content-between py-1"
    >
        <a
            v-if="label.helpIcon"
            class=""
            role="button"
            @click="iconClick"
        ><i class="bi bi-question-circle" /></a>
        <h3
            v-if="label.mathChar"
            class="input-single-label justify-content-start d-flex"
        >
            {{ label.text }} (<span class="m-char">{{ label.mathChar }}<sub>{{ label.subScript }}</sub></span>)
        </h3>
        <h3
            v-else
            class="input-single-label justify-content-start d-flex"
        >
            {{ label }}
        </h3>
        <input
            v-b-tooltip.hover.bottom="tip"
            :placeholder="placeHolder"
            :class="styleClass"
            :value="value"
            @input="updateInput"
            @keyup.enter="keyupEnter"
        >
    </div>
</template>

<script>
export default {
    name: 'InputSingle',
    model: {
        prop: 'value',
        event: 'update'
    },
    props: {
        value: {
            type: [String, Boolean, Number],
            default: '',
        },
        label: {
            type: [String, Object],
            default: '',
        },
        styleClass: {
            type: String,
            default: 'eq-inline-input',
        },
        group: {
            type: Boolean,
            default: false,
        },
        placeHolder: {
            type: String,
            default: '?',
        },
        tip: {
            type: String,
            default: ''
        }
    },
    methods: {
        updateInput (event) {
            this.$emit('update', event.target.value)
        },
        keyupEnter (event) {
            this.$emit('enter-pressed')
        },
        iconClick (event) {
            this.$emit('icon-click')
        }
    }
}
</script>
